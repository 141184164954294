import listdown from './listdown';
import text from './text';
import button from "./button";
import modal from "./modal";
import textinput from "./textinput";
import checkbox from "./checkbox";
import listdownoverlay from "./listdownoverlay";
import searchbox from "./searchbox";
import copy from "./copy";
import imagepicker from "./imagepicker";
import table from "./table";
import link from './link';
import popmenu from './pop_menu';

export const Text = (props) => {return text(props)}
export const ListDown = (props) => {return listdown(props)}
export const ListDownoverlay = (props) => {return listdownoverlay(props)}
export const Button = (props) => {return button(props)}
export const Modal = (props) => {return modal(props)}
export const TextInput = (props) => {return textinput(props)}
export const CheckBox = (props) => {return checkbox(props)}
export const SearchBox = (props) => {return searchbox(props)}
export const Copy = (props) => {return copy(props)}
export const ImagePicker = (props) => {return imagepicker(props)}
export const Table = (props) => {return table(props)}
export const Link = (props) => {return link(props)}
export const PopMenu = (props) => {return popmenu(props)}
