import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,FlatList,Linking,Image, ActivityIndicator} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import CryptoJS from 'crypto-js';
import moment from 'moment'
import COLORS from "../../../../assets/component/colors";
import {Modal,TextInput,Copy,Button,ListDown,Link} from "../../../../assets/component";
import {UpdateDriver,CURRENT_ACC,GetAllDocs,GetDocument,DeleteDelivery } from "../../../../assets/js/firebase";
import Searchinput from '../../../Order/deliveryorder/search';

export default function UpdateDeliveryModal(props) {

    const {clear,data,update,cancel} = props

    const [values,setValues] = useState({'driver_id':'','name':'','mobile':'','address':'','in-house':'','status':''})
    const [transporterList, setTransporterList] = useState([]);

    const [isLoading, setIsloading] = useState(false);
    const [account, setAccount] = useState(null);
    const [expand, setExpand] = useState(false);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
      GetAccount()
      getTransporters()
    }, []);




    useEffect(() => {
      setValues({'driver_id':'','name':'','mobile':'','address':'','in-house':'','status':'','remark':''})
      if(data){
             const DeliveryProg = [...data.delivery_status].reverse()
              changeHandler(data.driver_id,'driver_id')
              changeHandler(data.id,'id')
              changeHandler(data.name,'name')
              changeHandler(data.mobile,'mobile')
              changeHandler(data.delivery,'address')
              changeHandler(data.company_transporter,'in-house')
              changeHandler(DeliveryProg[0].status,'status')
              data.remark && changeHandler(data.remark,'remark')
              setExpand(false)
              setProducts([])
      }
    }, [data]);

    async function getTransporters(){

      const DataList  = await GetAllDocs('transporter_list')


      setTransporterList(DataList);
    };


    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }


    function changeHandler (val,type){
      setValues( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }

    function ShopeeFormat(data){
      var regex = /\b['6']\d{8,12}/
      let output = data.match(regex)
      if(!output || !output.length){ return null }
      const Mobile = output && ValidatePhone(output[0],'mobile')
      const AfterMobile = data.split(output[0]).pop().trim();
      const BeforeMobile = data.split(output[0]).shift().replaceAll(',', '').trim()
      return {'name':BeforeMobile,'mobile':Mobile,'address':AfterMobile}
    }



    async function Validate(){

      const validName = Validation(values.name,'name');

      const validMobile = ValidatePhone(values.mobile,'mobile')


 
      if(validName && validMobile){
        setIsloading(true)
        
        const NewData = {}
        NewData['delivery_status'] = data.delivery_status
        NewData['remark'] = values.remark
        NewData['driver'] = {
          'name':validName,
          'mobile':validMobile,
          'id':values.driver_id,
          'in-house':values['in-house']?true:false
        }
        if(data.type == 'online_order'){
            if(values.address){
                  const Formatted = ShopeeFormat(values.address)

                  if(Formatted){
                    NewData['name'] = Formatted.name
                    NewData['mobile'] = Formatted.mobile
                    NewData['delivery'] = Formatted.address
                  }else{
                    NewData['delivery'] = values.address
                  }
            }
        }else{
          NewData['delivery'] = values.address
        }
        const AddedDriver = await UpdateDriver(data,values.status,NewData,data.type)
        if(AddedDriver){
          const AddStatus = [...data.delivery_status,{'status':values.status,'updated':null}]
          NewData['driver_id'] = values.driver_id
          NewData['name'] = values.name
          NewData['mobile'] = values.mobile
          NewData['delivery_status'] = AddStatus
          let merged = {...data,...NewData};
          update(merged)
          UpdateTransporter(AddedDriver)
        }
        ClearState()
      }

  }


  
  function UpdateTransporter(data){
    const NewArray = [...transporterList]
    const found = NewArray.some(el => el.id === data.driver_id);
    if (!found) NewArray.push(data);
    setTransporterList(NewArray)
  }

  function Validation(input,change){
    if(!input || input.error){
      changeHandler({error:"Invalid Value"},change)
      return null
    }
    return input
}


    function ValidatePhone(data,change) {
        const Input = !data.error && data ? data.replace(/\D/g,'').replace('6','') : null;
        if(Input){
          if( /^\d+$/.test(Input) &&  ( (Input.charAt(0) === '0' && Input.charAt(1) === '1' && Input.length >= 10 && Input.length <= 11) || (Input.charAt(0) === '1' && Input.length >= 9 && Input.length <= 10) )  ){
            if(Input.charAt(0) === '1'){ Input = '0' + Input;}
            return '+6'+Input;
          } 
        }
        changeHandler({error:"Invalid Value"},change)
        return null;
    }

    function ClearState(){
      setIsloading(false)
      clear(null)
      setError(null)
    }




    const DOLink = () => {

      if(data.proof) return null


      const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+data.id+'/'+data.mobile.slice(2)


      const Whatsapptxt = data.delivery+'%0a'+'%0a%0a'+Link


      return (
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
              <TouchableOpacity style={styles.btn}  onPress={()=>window.open(Link, '_blank')}>
                    <Ionicons name='link' size={16} color={COLORS.Link} />
                    <Text style={styles.link}>DO</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.btn} onPress={()=>Linking.openURL('whatsapp://send?text='+Whatsapptxt+'&phone='+data.mobile)}>
                    <Ionicons name='logo-whatsapp' size={16} color={COLORS.Link} />
                    <Text style={styles.link}>Whatsapp</Text>
              </TouchableOpacity>
              {loadOrder(data)}
        </View>
      )
    }

    const ProductListing = () => {

      if(!products.length) return null
      
        return(
          <View>
            <Text style={{fontWeight:'500',color:COLORS.Primary}}>Products</Text>
            <View style={{flexDirection:'row'}}><Text style={{flex:1,fontWeight:'500'}}>#</Text><Text style={{flex:5,fontWeight:'500'}}>Name</Text><Text style={{flex:1,fontWeight:'500'}}>Qty</Text></View>
            {products.map((val,i)=> {
                return (
                  <View key={i} style={{flexDirection:'row'}}>
                    <Text style={[styles.product,{flex:1}]}>{(i+1)}</Text>
                    <Text style={[styles.product,{flex:5}]}>{val.name}</Text>
                    <Text style={[styles.product,{flex:1}]}>{val.qty}</Text>
                  </View>
                )
          })}
          </View>
        )
      
    }

    const ProofDelivery = () => {
      if(!data.proof) return null
      return (
        <View style={{flexDirection:'row'}}>
            <TouchableOpacity style={{flex:1}} onPress={()=>Linking.openURL(data.proof.image, '_blank')}>
              <Image style={{width:150,height:120}} source={{uri: data.proof.image}} />
            </TouchableOpacity>
            <View style={{flex:1}}>
              <Image style={{width:150,height:120}} source={{uri: data.proof.signed}} />
            </View>
        </View>
      )
    }

    const loadOrder = (data) => {
      const [loading, setIsloading] = useState(false);

      async function Getdata(){
        setIsloading(true)
        const DataList  = await GetDocument(data.id,data.type)
        console.log(DataList)
        setProducts(DataList.products)
        setIsloading(false)
      }
      
      if(loading){
          return <ActivityIndicator size={'small'}/>
      }

      return(
        <TouchableOpacity style={styles.btn} onPress={()=>Getdata()}>
          <Ionicons name='logo-dropbox' size={16} color={COLORS.Link} />
          <Text style={styles.link}>Get Product</Text>
        </TouchableOpacity>
      )
    }

    const CallBtn = (props) => {
      const { customer_mobile } = props
      return(
        <View style={{flexDirection:'row'}}>
        <TouchableOpacity style={styles.btn} onPress={()=>Linking.openURL('whatsapp://send?text='+'HI'+'&phone='+customer_mobile)}>
          <Ionicons name='logo-whatsapp' size={16} color={'#0f960b'} />
          <Text style={{color:'#0f960b'}}>{customer_mobile}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.btn} onPress={()=>Linking.openURL(`tel:${customer_mobile}`)}>
          <Ionicons name='call' size={16} color={'#0f960b'} />
          <Text style={{color:'#0f960b'}}>{customer_mobile}</Text>
        </TouchableOpacity>
        </View>
      )
    }


    const ProgressDelivery = () => {
      const DeliveryProg = [...data.delivery_status].reverse()
      const DataList = expand?DeliveryProg:[DeliveryProg[0]]
      return (
        <View style={{marginVertical:10}}>
            <TouchableOpacity onPress={()=>setExpand(!expand)} style={{flexDirection:'row'}}>
                <Text style={{color:'#777',marginRight:5}}>Delivery Progress</Text>
                <Ionicons name='add-circle' size={16} color={'#347e7e'} />
            </TouchableOpacity>
            <Text style={{fontSize:10,color:'#ccc',marginBottom:5}}>click more to view more progress</Text>
            <FlatList
            showsHorizontalScrollIndicator={false}
            data={DataList}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            />
        </View>
      )
    }

    const RenderItem = ({item,index}) => {
      const bulletstyle = index == 0 ? styles.bullet : styles.bullet2
      return(
          <View style={{flexDirection:'row'}} key={index}>
                  <View style={{marginRight:5,width:20,alignContent:'center',alignItems:'center'}}>
                    <View style={bulletstyle}/>
                    <View style={{flex:1,borderRightWidth:1,borderStyle:'dashed',borderColor:'#ccc',marginBottom:1}}/>
                  </View>
                  <View style={{flex:1}}>
                        <Text style={{fontSize:12,color:'#347e7e',fontWeight:'400'}}>{item.status.toUpperCase()}</Text>
                        <Text style={{fontSize:10,color:'#aaa',marginBottom:4}}>{ReformatTime(item.updated)}</Text>
                  </View>
          </View>
      )
    }

    const ReformatTime = (data) => {
      if(!data) return null
      const format = moment(data.toDate()).format('DD/MM/YY HH:MM');
      return format
    }

    async function VerifyRemove(){
      if (confirm('Are you sure you want to cancel delivery ?')) {
        RemoveOrder()
      } else {
        console.log('Cancel Delete');
      }
  }

  async function RemoveOrder(){
    setIsloading(true)
    const status = await DeleteDelivery(data.id,data.type)
    if(status){
      cancel(data.id)
      ClearState()
    }else{
      console.log("Error Remove")
    }
    setIsloading(false)
  }
    

    const ButtonRow = () => {
      if(isLoading) return <ActivityIndicator size={'small'}/>
      if(data.proof) return null
      return (
        <View style={{flexDirection:'row',flexWrap:'wrap',marginTop:10}}>
            <Button color={'red'} icon={'md-trash'} onPress={() => VerifyRemove()}/>
            <View style={{flex:1}}/>
            <Button title={'UPDATE'} style={{marginRight:10}} disable={data.proof?true:false} icon={'md-add-circle'} onPress={() => Validate()}/>
        </View>
      )
    }

    function InvoiceLink(data){

      var b64 = CryptoJS.AES.encrypt(data.id, 'donkeez').toString();
      var e64 = CryptoJS.enc.Base64.parse(b64);
      var eHex = e64.toString(CryptoJS.enc.Hex);
      const Link = 'https://us-central1-donkeezacc-edb84.cloudfunctions.net/app/invoice/'+account+'/'+data.type+'/'+eHex+'.pdf'
      Linking.openURL(Link)


    }

    const DeliveryInput = (add) => {
      return (
        <View style={{flexDirection:'row',alignContent:'center',alignItems:'center'}}>
              <View style={{flex:1}}><TextInput onChangeText={(val)=>changeHandler(val,'address')} uneditable={Proof} maxLength={560} title="Delivery Address" value={add} autoCapitalize="true" errclear={true}/></View>
              <View style={{width:30,padding:5}}><Copy link={add} /></View>
        </View>
      )
    }


    if(!data || !account) return null


    const Proof = data.proof?true:false
    

        return (
                <Modal title={'Update Delivery'} icon={'truck'}  Iconfont={'MaterialCommunityIcons'} visible={data?true:false} setVisible={()=>ClearState()}>
                        <View style={{flexDirection:'row'}}><Text style={{fontSize:18}}>Order ID : </Text><Link style={{fontSize:18}} onPress={()=>InvoiceLink(data)}>{data.id}</Link></View>
                        
                        <View style={{paddingVertical:10}}>
                          <Searchinput set={(val)=>setValues(val)} value={values} data={transporterList} uneditable={Proof}/>
                          {!values.driver_id && <TextInput onChangeText={(val)=>changeHandler(val,'mobile')} uneditable={Proof} maxLength={60} title="Mobile" value={values.mobile} autoCapitalize="true"errclear={true}/>}
                          {DeliveryInput(values.address)}
                          <TextInput onChangeText={(val)=>changeHandler(val,'remark')} uneditable={Proof} maxLength={560} title="Remark" value={values.remark} autoCapitalize="true" errclear={true}/>
                        </View>
                        {CallBtn(data)}
                        <DOLink/>
                        <ProductListing/>
                        <ProofDelivery/>
                        <ProgressDelivery/>
                        <ListDown height={150} name={'Status'} list={['processing','pickup']} onPress={(val)=>changeHandler(val,'status')} active={values.status}/>
                        <Text style={{color:'red'}}>{error}</Text>
                        <ButtonRow/>
                </Modal>
        )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    btn:{
        flexDirection:'row',
        alignContent:'center',
        padding:2,
        paddingHorizontal:5,
        marginBottom:5,
        marginRight:5,
        alignItems:'center',
        justifyContent:'center',
        borderWidth:1,
        borderColor:'#eee',
        borderRadius:3
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      link:{
        color:COLORS.Link,
        fontSize:16,
        marginLeft:2
      },
      bullet:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1,
        backgroundColor:'#428876'
      },
      bullet2:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1
      },
      product:{
        fontSize:14,
        color:'#444'
      }
  });
  